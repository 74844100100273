/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.v-avatar__underlay {
  opacity: 0.4;
  border-radius: inherit;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
}

.v-avatar {
  position: relative;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.font-weight-medium {
  font-weight: 500 !important;
}
.text-h6 {
  font-size: 1.25rem !important;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.0125em !important;
  text-transform: none !important;
}

.avatar.rounded {
  img {
    width: 40px !important;
    height: 40px;
    object-fit: cover;
  }
}

.avatar {
  background-color: #fff !important;
  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
}

.card-company-table thead th {
  background-color: #00aef0 !important;
  padding: 20px 30px;
  color: #fff;
}

.paddingbox {
  .rdt_TableCell {
    padding: 12px 15px;
    div {
      white-space: normal !important;
      font-weight: 500;
    }
  }
}

.card .table thead tr:last-child th:first-child {
  border-top-left-radius: 0.357rem !important;
}

.card .table thead tr:last-child th:last-child {
  border-top-right-radius: 0.357rem !important;
}

.badge-rejected {
  background-color: #fdebeb;
  color: #ec6566;
}

.badge-approved {
  background-color: #e6f8ee;
  color: #28c76f;
}

.badge-pending {
  background-color: #f5f5f5;
  color: #b4b5b8;
}

.badge {
  text-transform: capitalize;
}

.tabcustom tr td,
.tabcustom tr th {
  padding: 15px;
  border: 1px solid #e7f7fe;
}

.tabcustom tr th {
  background-color: #e7f7fe;
  color: #000;
  border-bottom: 1px solid #ebebeb;
}

.tabcustom tr:last-child td,
.tabcustom tr:last-child th {
  border: 1px solid #e7f7fe;
}

.imgBox {
  width: 100%;
  height: 230px;
  object-fit: contain;
  padding: 9px;
  border: 1px solid #f5f5f5 !important;
}

.badge.badgeInfo {
  background-color: #00aef0;
  padding: 6px 10px;
  font-size: 12px;
  margin-right: 5px;
}

.tabsBox {
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 0;
  .nav-link {
    padding: 12px 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &.active {
      background-color: #00aef0 !important;
      color: #fff;
    }
  }
}

.tabsBox_1 {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  padding: 15px;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 30px;
}

[dir] .table-bordered > :not(caption) > * > * {
  border-width: 0;
}

.editorClassName {
  border: 1px solid #f1f1f1;
  padding: 0 15px;
}

.uploadBox {
  padding: 0.571rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #eeeeef;
  border-radius: 0.357rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  position: relative;
  cursor: pointer;
  h3 {
    font-size: 16px;
    margin-top: 8px;
  }
  input {
    height: 100%;
    width: 100%;
    position: absolute;
    cursor: pointer;
  }
}

.imgBox1 {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  div {
    position: relative;
    margin-right: 8px;
    .crossBox {
      position: absolute;
      top: -9px;
      right: -9px;
      background-color: red;
      border-radius: 100px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

.imgBox1 {
  width: 100px;
  height: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.rdt_TableRow {
  padding: 2px 0;
}

.modalBox {
  .modal-header {
    background-color: #00aef0;
    padding: 22px;
    .modal-title {
      color: #fff;
      font-size: 18px;
    }
    .close {
      margin: -2.2rem -1.4rem -0.8rem auto;
    }

    .btn-close {
      transform: translate(18px, -35px);
      &:focus,
      &:hover {
        transform: translate(18px, -30px);
      }
    }
  }
  // .modal-body {
  //   height: 95px;
  // }
}

.btn-primary {
  color: #fff !important;
}

.dflex {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

iframe {
  display: none;
}

.card-header {
  background: linear-gradient(180deg, #143057 0%, #2b4872 100%) !important;
}

.card-title {
  color: #fff !important;
}

h2.font-weight-bold.mb-1.card-title {
  color: #2f2729 !important;
  font-weight: 800 !important;
}

.cardFlex {
  .ml15 {
    margin-left: -15px;
  }
}

.avatar-content {
  &.stateavtar {
    border: 1px solid #00aef0;
    padding: 12px;
    width: 50px;
    height: 50px;
    &.approved {
      border-color: #00bc4c;
    }
    &.pending {
      border-color: #ffc107;
    }
    &.declined {
      border-color: #dc3545;
    }
    img {
      width: 32px !important;
      height: 32px;
      object-fit: contain;
      border-radius: 0;
    }
  }
}

.stats-box {
  background-color: rgb(255, 255, 255);
  color: rgba(51, 48, 60, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 6px;
  box-shadow: rgb(51 48 60 / 3%) 0px 3px 9px 1px,
    rgb(51 48 60 / 2%) 0px 8px 9px 0px, rgb(51 48 60 / 1%) 0px 1px 6px 4px !important;
  background-image: none;
  overflow: hidden;
  position: relative;
  margin-bottom: 25px;
  h3 {
    margin-bottom: 0px;
    color: #000;
    font-weight: 700;
  }
  p {
    color: #999;
    font-weight: 500;
  }
  .card-body {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.rdt_TableCol_Sortable {
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  color: #000;
}
.inputForm {
  background-color: #fff !important;
  opacity: 1 !important;
}
.react-dataTable header {
  padding-left: 0px;
  padding-right: 0px;
}
.react-dataTable header .mr-1 {
  margin-right: 0px !important;
}
.react-dataTable header input#search {
  width: 270px !important;
}
.user-avatar-section {
  border-radius: 15px;
  padding: 9px;
  border: 1px solid #f5f5f5 !important;
  background: #ffffff;
  margin-bottom: 20px;
  label#change-img {
    min-width: 170px;
    height: 50px;
    line-height: 50px;
    border-color: #091f3e !important;
    background-color: #091f3e;
    color: #fff;
    padding: 0.786rem 1.5rem;
    border-radius: 0.358rem;
    font-size: 14px;
    &:hover:not(.disabled):not(:disabled) {
      box-shadow: 0 8px 25px -8px #091f3e;
    }
  }
  .useravatar1 img {
    width: 145px;
    height: 145px;
    border-radius: 50%;
    object-fit: cover;
  }
  h4.viewusername {
    font-size: 16px;
    font-weight: 700;
    color: #000;
  }
  h4.viewvoucher {
    font-size: 14px;
    font-weight: 600;
    color: #383838;
    background: rgba(244, 129, 34, 0.08);
    padding: 13px;
    text-align: center;
    margin: 10px 0px 0px;
    span {
      display: block;
      margin-top: 9px;
      font-size: 24px;
      color: #f48122;
    }
  }
  h4.viewrating {
    font-size: 12px;
    font-weight: 600;
    color: #4c4c4c;
    .viewrate_icon {
      margin-right: 6px;
    }
  }
}

.form-control {
  border-radius: 2px !important;
  height: 3rem !important;
  border: 1px solid #efefef !important;
}
.input-group-text {
  border: 1px solid #efefef !important;
}

.auth-wrapper.loginWrapper {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  .auth-inner {
   background-color: rgba(0, 0, 0, 0.7);
  }
  .auth-bg1 {
    background-color: #fff;
  }
}

.fortext {
  font-weight: 600;
  font-size: 12px;
}

.headingB {
  font-weight: 800;
  font-size: 23px;
  margin-top: 20px !important;
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 15px;
  margin-bottom: 20px !important;
  color: #000;
}

.headingB1 {
  font-weight: 800;
  font-size: 23px;
  margin-top: 0px !important;
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 15px;
  margin-bottom: 20px !important;
  color: #000;
}

.select__control {
  min-height: 42px !important;
  border-radius: 2px !important;
  background-color: #fff !important;
}

.form-control:focus:valid,
.form-control:focus.is-valid {
  box-shadow: none !important;
  border-color: #00aef0 !important;
}

.swal2-confirm.btn-primary {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.swal2-confirm.btn-success {
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}

.swal2-cancel.btn-outline-danger {
  border: 1px solid #9b9a9a !important;
  color: #9b9a9a;
  &:hover {
    border: 1px solid #000 !important;
    color: #000 !important;
    background-color: #fff !important;
  }
}

.textareaHeight {
  height: 120px !important;
}

.cardbox {
  padding: 20px 20px;
  background: #e7f7fe;
  border-radius: 4px;
  .headingB {
    margin-top: 0 !important;
    border-bottom: 1px solid #e7e2e2;
  }
}

.mr1 {
  margin-right: 5px;
}

.h3Head {
  color: #fff;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 18px !important;
}

.video {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
}

.swal2-styled.swal2-confirm {
  color: #fff !important;
}

.posXabs {
  position: absolute;
  top: 0;
  right: 0px;
  background-color: red;
  border-radius: 100px;
  width: 33px;
  height: 33px;
  line-height: 33px;
  text-align: center;
}

.bgsidebar {
  position: relative;
  .bgImg {
    position: absolute;
    left: -163px;
    top: -70px;
    z-index: -1;
  }
  .bgImg1 {
    position: absolute;
    bottom: -125px;
    z-index: -1;
    right: -125px;
  }
}
.orange {
  color: #091f3e;
}

.rdt_Pagination {
  font-weight: 600;
  color: #625f6e;
}

.positionabs {
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(-180deg);
  z-index: 1;
}

.zindex1 {
  z-index: 1;
}

.addbtn {
  padding: 8px;
}

.badgeView.badge-secondary {
  background: #00993e !important;

  margin-right: 5px;
  display: inline-block;
  margin-bottom: 5px;
}

.headH1 {
  font-size: 19px;
  font-weight: 700;
  color: #000;
}

.badge-warning {
  background-color: #e5ac00;
}

.badge-danger {
  background-color: #dc3545;
}

.modal-header {
  padding: 1rem 1.4rem;
  background: linear-gradient(180deg, #143057 0%, #2b4872 100%) !important;
  .modal-title {
    margin-bottom: 0;
    font-size: 18px;
    color: #fff;
  }
}

.css-2b097c-container {
  z-index: 2;
}

.imgCarsouel {
  .carousel-item {
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }
  .carousel-indicators {
    display: none;
  }
}

.iconSearch {
  position: absolute;
  top: 14px;
  right: 15px;
}

.iconSearch1 {
  position: absolute;
  top: 14px;
  left: 15px;
  z-index: 9;
}

.filterBox {
  .select__control {
    padding-left: 30px;
  }
}

.posrelative {
  position: relative;
}

.lighImg {
  img {
    width: 70px;
    height: 70px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

.badge-success {
  background-color: #00993e;
}

.badge-danger {
  background-color: #c50013;
}

.approvedbg {
  background-color: #00993e !important;
  border: 1px solid #00993e !important;
  color: #fff !important;
  transition: all ease 0.3s;
  font-size: 12px;
  padding: 8px;
  height: 35px;
  svg {
    margin-right: 5px;
  }
  &:hover {
    opacity: 0.9;
  }
}

.declinebg {
  background-color: #c50013 !important;
  border: 1px solid #c50013 !important;
  color: #fff !important;
  transition: all ease 0.3s;
  font-size: 12px;
  padding: 8px;
  height: 35px;
  svg {
    margin-right: 5px;
  }
  &:hover {
    opacity: 0.9;
  }
}

.Toastify {
  .toast-message {
    .Toastify__progress-bar--default.Toastify__progress-bar {
      background: #eb2323;
    }
  }
}

.react-dataTable.paddingbox .rdt_Table {
  min-height: 170px;
}
.rdt_Pagination {
  border: none!important;
}
@media only screen and (max-width:767px)
{
  .card .card-header .card-title
  {
    margin-bottom:10px;
  }
}